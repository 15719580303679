import React from "react"
import HeroIcon1 from "./images/healthIcon.svg"
import HeroIcon2 from "./images/healthIcon2.svg"
import {
  FlowHeaderRpm,
  FlowHeadingRpm,
} from "../rmt-section/styles/rpmWorkflowElements"
import {
  FlowCont,
  FlowInnerCont,
  FlowWrapper,
  Hr,
  Icon1,
  IconCont,
  MtmflowCard,
  MtmIconBox,
  MtmText,
  MtmWrapper,
  
} from "./styles/mtmWorkflowElements"
const mtmFlowData = [
  {
    icon: HeroIcon2,
    text: "The most common type of medical intervention is medication. However, studies suggest that up to 50% of drugs are not taken as recommended, and that pharmaceuticals are involved in 5-17% of hospital admissions, with up to 80% of them avoidable.",
  },
  {
    icon: HeroIcon1,
    text: " This is a confidential medication review with the pharmacist aimed at reaching a treatment understanding with the patient, optimizing the impact of medications, minimizing the number of medication-related problems, and reducing waste.",
  },
]
export default function WorkflowMtm() {
  return (
    <FlowCont>
      <FlowInnerCont>
        <FlowHeaderRpm>
          <FlowHeadingRpm>Let's see how it works</FlowHeadingRpm>
          <Hr />
        </FlowHeaderRpm>
        <FlowWrapper>
          {mtmFlowData.map((item, index) => (
            <MtmflowCard key={index}>
              <MtmIconBox>{`0${index + 1}`}</MtmIconBox>
              <IconCont>
                <Icon1 src={item.icon} />
              </IconCont>
              <MtmWrapper>
                <MtmText>{item.text}</MtmText>
              </MtmWrapper>
            </MtmflowCard>
          ))}
        </FlowWrapper>
      </FlowInnerCont>
    </FlowCont>
  )
}


