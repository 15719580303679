import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { BsArrowRight } from "react-icons/bs"
import { StaticImage } from "gatsby-plugin-image"
const CardContainer = styled.div`
  font-family: oswald;
  @media screen and (min-width: 425px) {
    margin: 4em 0 -4em 0;
  }
  @media screen and (min-width: 768px) {
    margin: 4em;
  }
  @media screen and (min-width: 1024px) {
    margin: 0 5em;
  }
`
const InnerCont = styled.div`
  margin: 2em;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  @media screen and (min-width: 320px) {
    flex-direction: column;
    margin: 1em;
  }
  @media screen and (min-width: 425px) {
    flex-direction: column;
    margin: 1em;
  }
  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`
const ImgCont = styled.div`
  padding: 2em;
  width: 40%;
  @media screen and (min-width: 320px) {
    height: 50vh;
    padding: 0;
    width: 100%;
  }
  @media screen and (min-width: 425px) {
    padding: 0;
    width: 100%;
  }
  @media screen and (min-width: 768px) {
    padding: 0;
    width: 80%;
  }
  
  @media screen and (min-width: 1440px) {
    padding: 2em;
    width: 30rem;
  }
`

const CardImg = styled.div`
  width: 100%;
  object-fit: cover;
  @media screen and (min-width: 320px) {
    width: 100%;
    height: 100%;
  }
`
const Card = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
  height: 25em;
  background: white;
  /* color: white; */
  width: 35%;
  padding: 3em;
  border-radius: 8px;
  @media screen and (min-width: 320px) {
    width: 100%;
    margin: 3em 0;
    height: 27em;
    padding: 1em;
  }
  @media screen and (min-width: 425px) {
    width: 100%;
    margin: 3em;
    height: 23em;
  }
  @media screen and (min-width: 768px) {
    width: 100%;
    margin: 3em;
    height: 17em;
  }
  @media screen and (min-width: 1024px) {
    width: 80%;
    margin: 1em;
    height: 22em;
    padding: 2em;
  }
  @media screen and (min-width: 1440px) {
    width: 45%;
    height: 17em;
    padding: 3em;
  }
`

const TopCont = styled.div`
  height: 80%;
  display: flex;
  flex-direction: column;
`
const CardTitle = styled.h2`
  font-family: oswald;
  margin-bottom: 2.5rem;
  font-size: 3em;
  @media screen and (min-width: 320px) {
    font-size: 2em;
    margin: 0 0 0.8em;
  }
  @media screen and (min-width: 425px) {
    font-size: 2em;
  }
`
const CardDesc = styled.p`
  font-size: 2em;
  color: gray;
  @media screen and (min-width: 425px) {
    font-size: 1.8em;
  }
  @media screen and (min-width: 425px) {
    font-size: 1.5em;
  }
`
const LearnMoreBtn = styled(Link)`
  display: flex;
  align-items: center;
  border-radius: 10px;
  background: transparent;
  white-space: nowrap;
  color: #1d9682;
  font-size: 20px;
  outline: none;
  border: 2.5px solid #1d9682;
  cursor: pointer;
  padding: 1.2em;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-align: "center";

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #2e4158;
    color: #f8f9fa;
  }
`
const Icon = styled(BsArrowRight)`
  margin: 0 0 0 15px;
`
const BottomCont = styled.div`
  height: 20%;
  display: flex;
  justify-content: end;
  @media screen and (min-width: 320px) {
    margin: 1em 0;
    height: 10%;
  }
`
export default function MtmProvCard() {
  return (
    <CardContainer>
      <InnerCont>
        <ImgCont>
          <CardImg>
          <StaticImage  imgStyle={{ borderRadius: '20px' }} src="../../images/MtmCardHero.webp" alt="Medication Therapy Management" placeholder="blurred" />
          </CardImg>
        </ImgCont>
        <Card>
          <TopCont>
            <CardTitle>
              Are you a Provider? Join your fellow Providers
            </CardTitle>
            <CardDesc>
              Register to be a Medication Therapy Management Provider
            </CardDesc>
          </TopCont>
          <BottomCont>
            <LearnMoreBtn to="/medication-therapy-management-provider">
              Get Started <Icon />
            </LearnMoreBtn>
          </BottomCont>
        </Card>
      </InnerCont>
    </CardContainer>
  )
}
