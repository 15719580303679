import React, { useState } from "react"
import "./styles/faq.scss"

const listItems = [
  {
    id: 0,
    title: "How do I join Afyabook for Medication therapy management?",
    body: "Send us an email to support@patameds.com and our representative will get in touch with on boarding details ",
  },
  {
    id: 1,
    title: "Do I pay anything to be on Afyabook?",
    body: "No, it’s free to have an account on Afyabook with free tools to manage your healthcare, though you might be required to subscribe to enjoy premium benefits like exclusive discounts, patient monitoring, health data analytics and advanced features. ",
  },
  {
    id: 3,
    title: "What is Medication review?",
    body: "A medication review is a private, confidential discussion with a pharmacist or another appropriately certified health expert to discuss your medications.The aim of the medication review is to check that you are prescribed the most appropriate medicines and that you get the best out of those medicines.To see if the medicine is functioning, tests may be performed (e.g., blood pressure checks). The type of medication you're taking may necessitate monitoring.You will be asked how you are getting on with your medicines; so, inform your health professional of any problems you may be experiencing with your medicines.You will have the opportunity to ask any questions you may have about your medicines. If any changes need to be made to your medication, your agreement will be sought before changes are made. A record of the review will be documented in your medical notes. ",
  },
  {
    id: 4,
    title: "Are these medication reviews necessary?",
    body: `These reviews are made to ensure: The medication prescribed is appropriate for the patient’s needs.
    The medication is effective for the patient.
    The medication is a cost-effective choice.
    Required monitoring is up to date.
    There are no significant drug interactions.
    The patient is not experiencing unacceptable side effects.
    The patient is compliant with their treatment. Reasons for poor compliance should be explored. A concordant approach should be taken to all prescribing decisions.
    Whether the patient is taking any over the counter and complementary medicines.
    Lifestyle and non-medicinal interventions have been utilized / considered.
    Whether there is any unmet need.
    `,
  },
  {
    id: 5,
    title: "Who does the medication review?",
    body: "Reviews can only be carried out by appropriate doctors, clinical pharmacists, pharmacists and other medical practitioners who possess the competencies required to carry out effective medication review.",
  },
  {
    id: 6,
    title: "Preparing for your medication review",
    body: `Mark the date and time of the appointment and who you are going to see for the medication review in your calendar or patient portal.It could be an in-person, tele consult or home visit depending with your preference. Make a list of all medication that you take. This includes:
    Any medicines that are prescribed for you.
    Any medicines that you buy over the counter from the chemist or supermarket or other stores e.g., herbal medicines, vitamins etc.
    Any medicines that you no longer take.
    NB: If you are not able to or haven’t managed to make a medication list, bring all your medicines to the medication review meeting.
    Make a list of questions that you may have about your medicines.
    Some questions that you may wish to consider:
    Why is it important to take this medicine(s)?
    When and how to take the medicine(s)?
    How long is the medicine(s) to be taken for?
    How do I know the medicine is working?
    What should I do if I have problems with the medicine?
    Are there any medicines or food that I should avoid taking whilst on these medicine(s)?
    What will happen if I miss a dose of the medicine or stop taking it?
    `,
  },
  {
    id: 7,
    title: "After the medication review",
    body: `Your regular doctor will be informed of any medication changes agreed by you at the meeting.
    A summary of the meeting will be documented in your medical record.
    Any tests or referrals to other health care professionals if required will be agreed and acted upon.
    `,
  },
  {
    id: 8,
    title: "Principles of medication review",
    body: "All patients should have a chance to raise questions and highlight problems about their medicines. Medication review seeks to improve or optimize impact of treatment for an individual patient. The review is undertaken in a systematic way by a competent person. Any changes resulting from the review are agreed with the patient. The review is documented in the patient notes. The impact of any change is monitored",
  },
  {
    id: 9,
    title:
      "Which patient are at risk for medication related problems?",
    body: `Taking four or more medicines every day (especially in over 75’s).
    On a complex medication regimen or more than 12 doses in a day.
    Recently discharged from hospital.
    Recently transferred to care home.
    Frequent hospital admissions.
    With multiple disease.
    Receiving medicines from more than one source e.g. specialist and primary doctor.
    Taking higher risk medicines - those requiring special monitoring e.g. lithium; those with a wide range of side effects e.g. NSAIDs; or a narrow therapeutic range e.g. digoxin; or on drugs not commonly used in primary care.
    Symptoms suggestive of an adverse drug reaction.
    Longstanding use of psychotropic medication.
    Where non-compliance is suspected or known.
    Significant changes to the medication regimen in the past 3 months or more than 4 changes in medication in the past 12 months.
    Special needs.
    Older people.
    Residents in care homes.
    Learning difficulties.
    Sensory impairment e.g. sight or hearing.
    Physical problems e.g. arthritis, swallowing difficulties
    Mental states such as confusion, depression, anxiety, serious mental illness
    Communication difficulties
    Literacy or language difficulties
    Minority ethnic groups.
    Refugees and asylum seekers.
    Living alone or poor caretaker support.
    Recent falls.
    `,
  },
  {
    id: 10,
    title:
      "How do I get more information about the Medication therapy management program?",
    body: "If you would like additional information, contact our team at okich@patameds.com or +254 792 640 973",
  },
  
]

export default function Faq() {
  const [active, setActive] = useState(null)
  return (
    <div className="faq">
      <section className="list">
        <div className="faqheader">
          <h1 className="heading">Frequent Questions</h1>
          <h3 className="subheading">Some frequent questions clients ask</h3>
        </div>
        {listItems.map(item => (
          <button
            key={item.id}
            className="list-item"
            open={active === item.id || false}
            onClick={e => {
              e.preventDefault()
              if (active === item.id) {
                setActive(null)
              } else {
                setActive(item.id)
              }
            }}
          >
            <header className="list-item-title">
              <p>{item.title}</p>
              <i className="list-item-plus-icon" />
            </header>
            <div className="list-item-body">
              <article className="list-item-article">{item.body}</article>
            </div>
          </button>
        ))}
      </section>
    </div>
  )
}

// list: [
//   {
//     review:
//       "The medication prescribed is appropriate for the patient’s needs",
//   },
//   {
//     review: "The medication is effective for the patient",
//   },
//   {
//     review: "The medication is a cost-effective choice",
//   },
//   {
//     review: "Required monitoring is up to date",
//   },
//   {
//     review: "There are no significant drug interactions",
//   },
//   {
//     review: "The patient is not experiencing unacceptable side effects",
//   },
//   {
//     review:
//       "The patient is compliant with their treatment. Reasons for poor compliance should be explored. A concordant approach should be taken to all prescribing decisions.",
//   },
//   {
//     review:
//       "Whether the patient is taking any over the counter and complementary medicines",
//   },
//   {
//     review:
//       "Lifestyle and non-medicinal interventions have been utilized / considered",
//   },
//   {
//     review: "Whether there is any unmet need",
//   },
// ],
