import React from "react"
import { MdInsights } from "react-icons/md"
import { GiComputing } from "react-icons/gi"
import { GrUserExpert } from "react-icons/gr"
import { GrSystem } from "react-icons/gr"
import {
  Card,
  CardDesc,
  CardTitle,
  Container,
  Header,
  IconSpan,
  InnerCont,
  Slogan,
  Title,
  Wrapper,
} from "../provider-section/styles/whyUs"
const HeaderData = [
  {
    intro: "Why Us",
    subtitle: "",
  },
]
const SectionData = [
  {
    id: 1,
    icon: <MdInsights />,
    title: "Data driven",
    desc: "We use statistical software to harvest and communicate insights from obtained data",
  },
  {
    id: 2,
    icon: <GiComputing />,
    title: "Tech driven",
    desc: "Skilled in technology and digital communication, presenting healthcare information in an easy format",
  },
  {
    id: 3,
    icon: <GrUserExpert />,
    title: "Expertise",
    desc: "Competent licensed healthcare professional offering medical services in Kenya within the platform",
  },
  {
    id: 4,
    icon: <GrSystem />,
    title: "Patient Portal",
    desc: "A portal that stores your healthcare information, can be accessed at any time and from anywhere",
  },
]

export default function MtmWhyUs() {
  return (
    <Container>
      <Wrapper>
        <Header>
          {HeaderData.map(item => {
            const { intro, subtitle } = item
            return (
              <>
                <Title>{intro}</Title>
                <Slogan>{subtitle}</Slogan>
              </>
            )
          })}
        </Header>
        <InnerCont>
          {SectionData.map(item => {
            const { id, title, desc, icon } = item
            return (
              <>
                <Card key={id}>
                  <IconSpan>{icon}</IconSpan>
                  <CardTitle>{title}</CardTitle>
                  <CardDesc>{desc}</CardDesc>
                </Card>
              </>
            )
          })}
        </InnerCont>
      </Wrapper>
    </Container>
  )
}
