import React from "react"
import Seo from "../components/seo"
import BannerSection from "../sections/service-section/banner"
import WorkFlowSection from "../sections/service-section/workflow"
import BenefitsSection from "../sections/service-section/MtmBenefits"
import MtmWhyNow from "../sections/service-section/MtmWhyNow"
import MtmWhyUs from "../sections/service-section/MtmWhyUs"
import MtmProvCard from "../sections/service-section/MtmProvCard"
import Testimonials from "../components/testimonial/Testimonial"
import Partners from "../components/partners/Partners"
import MtmFaq from "../sections/faq/MtmFaq"
import Footer from "../components/homeNav/footer/Footer"
import BotNav from "../components/bottomNav"

export default function service({ toggle }) {
  return (
    <>
      <Seo
        title="Get the most out of your medication through MTM | Afyabook"
        description="Medication Therapy Managment program. Save on cost and time by minimizing medication related problems to improve quality of life"
        img="https://www.afyabook.com/static/f4d025f6a72927f1deeff9beae5855fe/efceb/mtmHeroImg.webp"
        keywords="medication therapy management kenya, medication management, medication optimization, MTM"
        siteUrl="https://www.afyabook.com/medication-therapy-management"
        canonical="https://www.afyabook.com/medication-therapy-management"
      ></Seo>
      <BannerSection />
      <WorkFlowSection />
      <BenefitsSection />
      <MtmWhyNow />
      <MtmWhyUs />
      <MtmProvCard />
      {/* <Testimonials /> */}
      <MtmFaq />
      <Partners />
      <BotNav />
      <Footer />
    </>
  )
}
