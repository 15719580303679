import styled from 'styled-components';

export const TestimonialHeader = styled.div`
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
`;
export const TestimonialHeading = styled.h1`
  font-family: Oswald;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 53px;
  color: #171717;
`;
export const TestimonialContainer = styled.div`
  display: block;
  position: relative;
  background-color: rgb(255, 255, 255);
  min-width: 384px;
  width: 384px;
  min-height: 281px;
  height: 281px;
  border-radius: 5px;
  border: 1px solid rgb(223, 225, 229);
  margin: 57px 13px 5px 3px;
  box-sizing: border-box;
  padding: 0px 30px 33px;
  transition: box-shadow 0.3s ease-in-out 0s;
  cursor: pointer;
`;
export const TestimonialWrapper = styled.div`
  display: inline-block;
  flex-direction: column;
  position: absolute;
  top: -50px;
`;
export const InnerContainer = styled.div`
  position: relative;
  display: inline-block;
`;
export const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  /* width: 100px;
    height: 100px;
    clip-path: circle(50% at 50% 50%);
    border-radius: 50%; */
  overflow: hidden;
  animation: 4s linear 0s infinite normal forwards running shimmer;
  /* background: linear-gradient(
        to right,
        rgb(247, 248, 249),
        white 10%,
        rgb(247, 248, 249) 15%
      )
      0% 0% / 1500px 100%; */
`;
export const Avatar = styled.img`
  animation: 0.3s ease-out 0s 1 normal none running;
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;
export const DetailContainer = styled.div`
  cursor: pointer;
  margin-bottom: 3px;
  margin-top: 87px;
`;
export const DetailInner = styled.div`
  display: flex;
  flex-direction: column;
`;
export const DetailHeader = styled.div`
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  color: rgb(0, 35, 75);
  font: 20px / 23px sharp-sans-bold, fallback-font, Arial, sans-serif;
`;
export const Name = styled.span``;
export const NameInner = styled.span`
  white-space: nowrap;
`;
export const DetailSpecialty = styled.div`
  color: rgb(0, 35, 75);
  font: 14px / 20px sharp-sans-medium, fallback-font, Arial, sans-serif;
  margin-top: 2px;
`;
export const DetailLocation = styled.div`
  font: 14px / 20px sharp-sans-medium, fallback-font, Arial, sans-serif;
  color: rgb(102, 115, 133);
  white-space: nowrap;
  overflow: hidden;
`;
export const DescContainer = styled.div`
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inherit;
  height: 94px;
  min-height: 94px;
`;
export const Reviews = styled.span`
  display: flex;
  align-items: center;
  text-align: left;
`;
export const TotalReviews = styled.span`
  font: 13px sharp-sans-medium, fallback-font, Arial, sans-serif;
  color: rgb(102, 115, 133);
  margin-left: 6px;
  display: inline;
`;
export const ReviewHeader = styled.span`
  font: 13px sharp-sans-medium, fallback-font, Arial, sans-serif;
  color: rgb(102, 115, 133);
  display: inline;
  white-space: nowrap;
  margin-left: 0px;
`;
export const ReviewDesc = styled.div`
  margin-top: 18px;
  font: 15px / 18px sharp-sans-medium, fallback-font, Arial, sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;
