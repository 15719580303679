import styled, { keyframes } from 'styled-components';

import {
  mobileL, mobileM, mobileS, tablet, desktop,
} from '../../../responsive';

export const BannerContainer = styled.div`
  font-family: oswald;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
    
  @media screen and (min-width: 768px) {
    padding: 0;
    height: 80vh;
  }
  @media screen and (min-width: 1024px) {
    height: 100vh;
    padding: 0 5em;
  }
  @media screen and (min-width: 1440px) {
    height: 100vh;
    padding: 0 5em;
  }
`;
export const BannerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  height:80vh;
  
  @media screen and (min-width: 320px) {
    flex-direction: column-reverse;
    margin: 0 2em;
  }
  
  @media screen and (min-width: 768px) {
    margin: 2em;
    flex-direction: row;
    justify-content: space-between;
  }
  @media screen and (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
  }
  @media screen and (min-width: 1440px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;
export const ScrollerContainer = styled.span`
  width: 25px;
  height: 50px;
  border: 2px solid #1d9682;
  display: flex;
  border-radius: 10px;
  position: absolute;
  top: 85%;
  cursor: pointer;
  @media screen and (min-width: 320px) {
    width: 20px;
    height: 40px;
    top: 90%;
  }
  @media screen and (min-width: 425px) {
    top: 95%;
  }
  @media screen and (min-width: 768px) {
    top: 68%;
  }
  @media screen and (min-width: 1024px) {
    top: 88%;
  }
  @media screen and (min-width: 1440px) {
    top: 85%;
  }
`;
const MoveWheel = keyframes`
0% {
  opacity:1;
  transform: translateY(1rem);

}
100%{
  opacity: 0;
  transform:translateY(-1rem);
}
`;
export const ScrollWheel = styled.span`
  display: block;
  width: 10px;
  height: 10px;
  background-color: #1d9682;
  border-radius: 50%;
  margin: auto;
  animation: ${MoveWheel} 2s linear infinite;
`;
export const TextContainer = styled.div`
  @media screen and (min-width: 1024px) {
    width: 50%;
  }
  @media screen and (min-width: 1440px) {
    width: 50%;
  }
`;
export const Heading = styled.h2`
  font-weight: 400;
  font-size: 60px;
  line-height: 80px;
  font-family: Oswald;
  font-style: normal;
  font-weight: bold;
  color: #007360;
  @media screen and (min-width: 320px) {
    font-size: 2.1em;
    line-height: 1em;
  }
  @media screen and (min-width: 425px) {
    font-size: 2.5em;
    line-height: 1.2em;
  }
  @media screen and (min-width: 768px) {
    font-size: 3em;
    line-height: 1.2em;
  }
  @media screen and (min-width: 1024px) {
    font-size: 3.5em;
    line-height: 1.3em;
  }
`;
export const Subtitle2 = styled.p`
  @media screen and (min-width: 320px) {
    font-size: 1.3em;
    line-height: 1em;
  }
  @media screen and (min-width: 425px) {
    font-size: 1.3em;
    line-height: 1em;
  }
  @media screen and (min-width: 768px) {
    font-size: 1.3em;
  }
  @media screen and (min-width: 1024px) {
    font-size: 1.7em;
  }
`;
export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: "#7c7c7c";
  @media screen and (min-width: 320px) {
    font-size: 1.1em;
    line-height: 1.3em;
    margin-bottom: 1em;
  }
  @media screen and (min-width: 425px) {
    font-size: 1.1em;
    line-height: 1.3em;
    margin-bottom: 1em;
  }
  @media screen and (min-width: 1024px) {
    font-size: 1.2em;
    line-height: 1.3em;
  }
  @media screen and (min-width: 1440px) {
    max-width: 20em;
    font-size: 1.5em;
    line-height: 1.2em;
  }
`;
export const ImgCont = styled.div`
  max-width: 600px;
  
  @media screen and (min-width: 320px) {
    margin: 1em 0;
  }
  
  @media screen and (min-width: 768px) {
    max-height: 95vh;
    max-width: 26em;
  }
  @media screen and (min-width: 1440px) {
    max-height: 95vh;
    max-width: 35em;
  }
`;

export const ImgCont2 = styled.div`
  max-width: 600px;
    
  @media screen and (min-width: 320px) {
    width: 60%;
    margin: 1em 0;    
  }
  @media screen and (min-width: 425px) {
    width: 50%;    
  }
  @media screen and (min-width: 768px) {
    max-height: 95vh;
    max-width: 26em;
  }
  @media screen and (min-width: 1440px) {
    max-height: 95vh;
    max-width: 35em;
  }
`;

export const HeroImg = styled.img`
  width: 600px;
  @media screen and (min-width: 320px) {
    width: 100%;
  }
  @media screen and (min-width: 425px) {
    width: 100%;
  }
  @media screen and (min-width: 768px) {
    width: 100%;
  }
  @media screen and (min-width: 1024px) {
    width: 100%;
  }
`;
export const BannerBtn = styled.button`
  /* box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%); */
  /* box-shadow: 2px 4px 25px rgb(0 0 0 / 25%); */
  border-radius: 10px;
  background: transparent;
  white-space: nowrap;
  color: #1d9682;
  font-size: 20px;
  outline: none;
  border: 2.5px solid #1d9682;
  cursor: pointer;
  padding: 14px 40px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-align: "center";
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #2e4158;
    color: #f8f9fa;
  }
  ${mobileS({ padding: '10px 100px', fontSize: '1em', width: '100%' })}
  ${mobileM({ padding: '10px 125px' })}
  ${mobileL({ padding: '10px 150px' })}
  ${tablet({
    padding: '10px ',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
  })}
  ${desktop({ padding: '10px 50px', width: '40%' })}
`;
export const ScrollText = styled.p`
  text-align: center;
  font-size: 17px;
  font-family: oswald;
  font-weight: 300;
  margin-top: -3em;
  color: #1d9682;
  @media screen and (min-width: 320px) {
    margin-top: -1.5em;
  }
  @media screen and (min-width: 425px) {
    margin-top: -2em;
  }
  @media screen and (min-width: 1440px) {
    margin-top: -3em;
  }
`;
